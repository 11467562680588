// home news owl caroulse js

jQuery(document).ready(function() {
  if ( $('#js-news-carousel')[0] ) {
    $('#js-news-carousel').owlCarousel({
      loop:true,
      margin:30,
      responsiveClass:true,
      responsive:{
        0:{
          items:1.2,
          nav:true,
          margin: 10,
          center: true
        },
        640:{
          items:4,
          nav:true,
          margin: 20,
          center: true
        },
        1200:{
          items:6,
          nav:true,
          center: true
        }
      }
    });
  }
});