/*
These functions make sure WordPress
and Foundation play nice together.
*/

jQuery(document).ready(function() {

    // Remove empty P tags created by WP inside of Accordion and Orbit
    jQuery('.accordion p:empty, .orbit p:empty').remove();

	 // Makes sure last grid item floats left
	jQuery('.archive-grid .columns').last().addClass( 'end' );

	// Adds Flex Video to YouTube and Vimeo Embeds
  jQuery('iframe[src*="youtube.com"], iframe[src*="vimeo.com"]').each(function() {
    if ( jQuery(this).innerWidth() / jQuery(this).innerHeight() > 1.5 ) {
      jQuery(this).wrap("<div class='widescreen flex-video'/>");
    } else {
      jQuery(this).wrap("<div class='flex-video'/>");
    }
  });

});

/**
 * SmoothScroll
 */
(function($) {
  $('.tabs-title > [href^="#"]').addClass('noscroll');
  $('[href^="#"]:not(".noscroll")').SmoothScroll({
    duration: 700,
    easing: 'swing',
    hash: false
  });
})(jQuery);


/**
 * PC dropdown menu
 */
(function($) {
  $('.header-menu > .menu > li').each(function() {
    if ( $(this).find('.header-menu-sub')[0] ) {
      $(this).addClass('has-sub-menu');
    }
  });

  // Hover
  $('.header-menu > .menu > li > a').hover(function() {
    $('.header-menu-sub').removeClass('is-active');
    $(this).siblings('.header-menu-sub').addClass('is-active');
  });
})(jQuery);


/**
 * Mobile menu
 */
(function($) {
  $('#mobile-nav-toggle').on('click', function() {
    $('#mobile-nav').toggleClass('is-active');
  });

  // Close
  window.document.onkeydown = function(event){
    if ( event.key === 'Escape' ) {
      if ( $('#mobile-nav').hasClass('is-active') ) {
        $('#mobile-nav').removeClass('is-active');
      }
    }
  };
})(jQuery);
